import { FetchFunction } from "relay-runtime";

export interface Props {
  endpoint: string;
  ssrToken?: string;
  rejectErrors: boolean;
  redirect: (path: string) => void;
  isK8?: boolean;
}

type CreateFetchFn = (p: Props) => FetchFunction;

const fn = process.browser
  ? (require("./fetchQueryClient").default as CreateFetchFn)
  : (require("./fetchQueryServer").default as CreateFetchFn);

// const Logger = createLogger(logger);

export default fn;
// export default process.env.NODE_ENV !== "production"
//   ? (props: Props) =>
//       Logger.wrapFetch(
//         fn(props),
//         process.browser ? createGraphiQLPrinter(props.endpoint) : undefined,
//       )
//   : fn;
