import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useRouter } from "next/router";

import { FeedbackContext } from "@contexts/FeedbackContext";
import { useTimeoutEffect } from "@hooks/useTimeoutEffect";

import { styled } from "@styles/theme";

import {
  Errors,
  unsafeVerifyTwoFactor,
  unsafeResendTwoFactor,
} from "@services/index";
import Button from "../Button";
import { Spacer } from "../Spacer";
import { Text } from "../Text";
import { CodeInput } from "../Input/CodeInput";
import Link from "../Link";
import { Message } from "../Message";
import { Spinner } from "../Spinner";
import { Flex } from "../ResponsiveBox";

const EXPIRE_TIME = 60;

const ResendTimer = ({ time }: { time: number }) => (
  <>
    You can resend it in{" "}
    <Text weight="bold" colorWeight="300">
      {time === EXPIRE_TIME ? "01:00" : `00:${String(time).padStart(2, "0")}`}
    </Text>
  </>
);

type Status = "IDLE" | "READY" | "LOADING" | "ERROR" | "Done";

interface Props {
  email: string;
  type: "form" | "modal";
  setShowModal?: (show: boolean) => void;
  setIsReady?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalCode?: React.Dispatch<React.SetStateAction<string>>;
  modalSubmitReturns?: ResponseProps;
}

export interface ResponseProps {
  ok?: any;
  error?: any;
  last_url?: any;
}

const Wrapper = styled.div`
  text-align: center;
`;

export const TwoFactorAuthenticationForm: React.FC<Props> = ({
  email,
  type,
  setShowModal,
  setIsReady,
  setModalCode,
  modalSubmitReturns,
}) => {
  const router = useRouter();
  const { setFeedback } = useContext(FeedbackContext);

  const timeRef = useRef(EXPIRE_TIME);
  const [expireTime, setExpireTime] = useState(EXPIRE_TIME);

  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState<string | null>(null);

  const [resend, setResend] = useState<Status>("IDLE");
  const [verify, setVerify] = useState<Status>("IDLE");

  const handleChange = useCallback(
    (value: string) => {
      if (codeError) setCodeError(null);
      setCode(value);
      setModalCode?.(value);
      if (value.length === 6) {
        setVerify("READY");
        setIsReady?.(true);
      } else {
        setVerify("IDLE");
        setIsReady?.(false);
      }
    },
    [codeError],
  );

  const handleSubmitResponse = ({ ok, error, last_url }: ResponseProps) => {
    if (ok) {
      // This page uses this information without having a session
      // Thus why we store the email in the localStorage
      localStorage.removeItem("email");

      if (type === "form") {
        router.push(last_url || "/instances");
      }

      if (type === "modal") {
        setFeedback({
          message: "Success! 2FA has been enabled successfully",
          color: "blue",
          duration: 5000,
        });
        setShowModal?.(false);
      }
    }

    if (error) {
      setVerify("ERROR");

      switch (error) {
        case Errors.CODE_INVALID:
          setCodeError(error);
          break;
        case Errors.CODE_EXPIRED:
          setCodeError(error);
          timeRef.current = 0;
          setExpireTime(0);
          break;
        case Errors.USER_ALREADY_VERIFIED:
          if (type === "form") {
            router.push("/instances");
          } else {
            setShowModal?.(false);
          }
        default:
          setError(
            "Something unexpected happened, please try again in a few minutes.",
          );
          break;
      }
    }
  };

  const handleSubmit = useCallback(async () => {
    if (verify !== "READY") return;

    setVerify("LOADING");

    const { ok, last_url, error } = await unsafeVerifyTwoFactor({
      email,
      code,
      without_validation: false,
    }).catch((error) => ({
      error,
    }));

    handleSubmitResponse({ ok, error, last_url });
  }, [verify]);

  useEffect(() => {
    if (modalSubmitReturns) {
      const { ok, error } = modalSubmitReturns;
      handleSubmitResponse({ ok, error });
    }
  }, [modalSubmitReturns]);

  const handleResendCode = useCallback(async () => {
    setResend("LOADING");
    setError("");

    const { ok, error } = await unsafeResendTwoFactor({ email }).catch(
      (error) => ({
        error,
      }),
    );

    if (ok && ok.sentAt) {
      timeRef.current = EXPIRE_TIME;
      setExpireTime(EXPIRE_TIME);
      setCode("");
    }

    if (error) {
      setError(error);
    }

    setResend("IDLE");
  }, [resend]);

  useTimeoutEffect(() => {
    if (timeRef.current > 0) {
      timeRef.current--;
      setExpireTime(timeRef.current);
    }
  }, 1000);

  return (
    <Wrapper>
      {error && (
        <Message color="red" mB="lg">
          {error}
        </Message>
      )}
      <CodeInput error={codeError || undefined} onChange={handleChange} />

      <Spacer size="lg" />

      {resend === "IDLE" && (
        <Text align="center" colorWeight="200">
          Didn't receive the code?{" "}
          <Text colorWeight="200">
            {expireTime > 0 && <ResendTimer time={expireTime} />}
            {expireTime === 0 && (
              <Link onClick={handleResendCode} href="">
                Resend
              </Link>
            )}
          </Text>
        </Text>
      )}

      {(resend === "READY" || resend === "LOADING") && (
        <Flex direction="row" alignItems="center" justify="center">
          <Text align="center" colorWeight="200">
            Resending
          </Text>
          <Spacer size="md" horizontal />
          <Spinner size={16} color="primary.300" />
        </Flex>
      )}

      {type === "form" && (
        <>
          <Spacer size="xhg" />
          <Button
            color="blue"
            block
            type="submit"
            onPress={handleSubmit}
            loading={verify === "LOADING"}
            disabled={
              (code.length !== 6 && verify !== "READY") || verify === "LOADING"
            }
          >
            Verify
          </Button>
        </>
      )}
    </Wrapper>
  );
};
