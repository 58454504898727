import React, { HTMLProps } from "react";

import IconSVG, { Props } from "@icons/Icon";
import { styled } from "@styles/theme";
import { Box, BoxProps } from "./ResponsiveBox";

const Container = styled(Box)`
  svg {
    display: block;
  }
`;

export function Icon({
  name,
  width,
  height,
  size,
  fill,
  ...props
}: Props & BoxProps & Omit<HTMLProps<HTMLDivElement>, "ref">) {
  return (
    <Container {...props} minW={size || width} height={size || height}>
      <IconSVG
        name={name}
        width={width || size}
        height={height || size}
        fill={fill}
      />
    </Container>
  );
}
