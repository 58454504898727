import React, { useCallback, useContext, useState } from "react";

import { UserContext } from "@contexts/UserContext";

import { useTimeoutEffect } from "@hooks/useTimeoutEffect";
import {
  Errors,
  unsafeSkipTwoFactor,
  unsafeVerifyTwoFactor,
  unsafeSendTwoFactor,
} from "@services/index";
import { Modal } from "../Modal";
import { Text } from "../Text";
import { H1 } from "../Headings";
import {
  ResponseProps,
  TwoFactorAuthenticationForm,
} from "./TwoFactorAuthenticationForm";
import { TwoFactorIcon } from "./TwoFactorIcon";

import { HeaderGradient } from "./styles";
import Link from "../Link";
import { Message } from "../Message";

interface TwoFactorProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onClose: () => void;
}

const TIMER_ERROR = 5;

const TwoFactorModal: React.FC<TwoFactorProps> = ({
  show,
  setShow,
  onClose,
}) => {
  const user = useContext(UserContext);

  const [step, setStep] = useState<1 | 2>(1);
  const [isReady, setIsReady] = useState(false);
  const [code, setCode] = useState("");
  const [modalSubmitReturns, setModalSubmitReturns] = useState<ResponseProps>(
    {},
  );

  const [error, setError] = useState<string | null>(null);
  const [timerError, setTimerError] = useState(TIMER_ERROR);

  const handleSubmit = useCallback(async () => {
    if (step === 1) {
      const { ok, error } = await unsafeSendTwoFactor({
        email: user.email,
      }).catch((error) => ({
        error,
      }));

      if (error) {
        switch (error) {
          case Errors.USER_ALREADY_VERIFIED:
            setShow(false);
            break;
          case Errors.CODE_RESEND_INTERVAL:
            setError(error);
            break;

          default:
            setError("Something weird just happened");
            break;
        }
      }

      ok && setStep(2);
    } else {
      if (!isReady) return;

      const { ok, error } = await unsafeVerifyTwoFactor({
        email: user.email,
        code,
        without_validation: false,
      }).catch((error) => ({
        error,
      }));

      ok && (await user.updateUser?.({ ...user, two_factor_enabled: true }));

      setModalSubmitReturns({ ok, error });
    }
  }, [step, isReady, code]);

  const handleCancel = useCallback(async () => {
    const { ok, error } = await unsafeSkipTwoFactor({
      email: user.email,
    }).catch((error) => ({
      error,
    }));

    if (error) {
      switch (error) {
        case Errors.USER_ALREADY_VERIFIED:
          setShow(false);
          break;

        default:
          setError(
            "Something unexpected happened, please try again in a few minutes.",
          );
          break;
      }

      return;
    }

    ok &&
      (await user.updateUser?.({ ...user, skippedTwoFA: true }),
      setShow(false),
      onClose());
  }, [user]);

  useTimeoutEffect(
    () => {
      if (error && timerError > 0) {
        setTimerError((value) => value - 1);
      } else {
        setTimerError(TIMER_ERROR);
        error && setError("");
      }
    },
    1000,
    [error],
  );

  const contactUrl = `https://usecoda.com/contact?action=2fa&email=${encodeURI(
    user.email,
  )}&subject=trial`;

  return (
    <Modal
      isOpen={show}
      shouldReturnFocusAfterClose={false}
      cancelLabel={step === 1 ? "Not now" : "Cancel"}
      closeIconColor="white.0"
      onRequestClose={handleCancel}
      onClickCancel={handleCancel}
      actions={{
        [step === 1 ? "Send code" : "Enable"]: {
          type: "button",
          disabled: step === 2 && !isReady,
          onPress: handleSubmit,
        },
      }}
      shouldCloseOnOverlayClick={false}
      style={{ overflowY: "auto" }}
    >
      <HeaderGradient />

      <TwoFactorIcon marginTop={-20} />

      <Text paragraph align="center" mY="md">
        {step} of 2
      </Text>

      {step === 1 && (
        <>
          {error && timerError > 0 && (
            <Message color="red" mB="lg">
              {error}
            </Message>
          )}

          <Text size={30} weight={600} align="center">
            Increase the security of your Fixr account with 2FA
          </Text>

          <Text paragraph align="center" mT="md">
            Enable now and protect your account from unauthorized access by
            requiring a security code received by email when logging in.
          </Text>
        </>
      )}

      {step === 2 && (
        <>
          <H1>Two-Factor Authentication</H1>

          <Text paragraph colorWeight="200" mT="sm">
            A 6-digit code was sent to <Text weight={600}>{user.email}</Text>.
          </Text>

          <Text paragraph colorWeight="200" mB="xxg">
            Please type the code below.
          </Text>

          <TwoFactorAuthenticationForm
            email={user.email}
            type="modal"
            setIsReady={setIsReady}
            setShowModal={setShow}
            setModalCode={setCode}
            modalSubmitReturns={modalSubmitReturns}
          />

          <Text paragraph colorWeight="200" mT="lg" mB="xg">
            Having an issue with 2FA? <Link href={contactUrl}>Contact us</Link>
          </Text>
        </>
      )}
    </Modal>
  );
};

export default TwoFactorModal;
