import { FetchFunction } from "relay-runtime";
import { Props } from "./fetchQuery";

const fetchQuery = ({
  endpoint,
  ssrToken,
  rejectErrors,
  redirect,
}: Props): FetchFunction => async (operation, variables) => {
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(ssrToken && { token: ssrToken }),
      },
      body: JSON.stringify({ query: operation.text, variables }),
    });

    // Relay fetch ignores json.errors, so we have to handle it manually.
    // But only for queries. Mutations are ok.
    if (response.status === 401) return redirect("/login");
    if (response.status !== 200) return {};

    const json = await response.json();

    return rejectErrors && json.errors ? Promise.reject(json.errors) : json;
  } catch (e) {
    console.warn("relay/fetchQueryClient error:", e);
    return {};
  }
};

export default fetchQuery;
