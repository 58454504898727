import { PageContext } from "pages/_app";
import { MessageType } from "../contexts/FeedbackContext";

export function getFlashFromCtx(ctx?: PageContext): undefined | MessageType {
  const flash = ctx?.res?.flash;

  if (!flash) {
    return undefined;
  }

  return {
    id: Math.random().toString(),
    duration: 5000,
    message: flash.msg,
    color: flash.type === "ok" ? "green" : "red",
  };
}
