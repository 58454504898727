import React from "react";

export const TwoFactorIcon = (style: React.CSSProperties | undefined) => (
  <svg
    width="384"
    height="289"
    viewBox="0 0 384 289"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style, position: "relative" }}
  >
    <path
      d="M82.1856 126.469C82.1856 126.469 72.3168 135.451 76.4832 151.747C79.3632 162.896 88.1568 192.133 56.4768 191.347L57.2832 198.556C57.2832 198.556 89.0304 202.707 94.08 175.712C99.84 145.2 82.1856 126.469 82.1856 126.469Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.2"
      d="M82.1856 126.469C82.1856 126.469 72.3168 135.451 76.4832 151.747C79.3632 162.896 88.1568 192.133 56.4768 191.347L57.2832 198.556C57.2832 198.556 89.0304 202.707 94.08 175.712C99.84 145.2 82.1856 126.469 82.1856 126.469Z"
      fill="white"
    />
    <path
      d="M301.882 67.3989H97.3345V200.109H301.882V67.3989Z"
      fill="#E6E6E6"
    />
    <path
      d="M184.157 184.953L176.381 234.236H228.202L216.288 184.953H184.157Z"
      fill="#C9C9C9"
    />
    <path
      opacity="0.45"
      d="M200.995 251.979C273.552 251.979 332.371 248.529 332.371 244.272C332.371 240.016 273.552 236.565 200.995 236.565C128.438 236.565 69.6191 240.016 69.6191 244.272C69.6191 248.529 128.438 251.979 200.995 251.979Z"
      fill="#E6E6E6"
    />
    <path
      d="M301.882 169.798H97.3345V200.109H301.882V169.798Z"
      fill="#DBDBDB"
    />
    <path
      d="M200.755 191.357C205.294 191.357 208.973 187.683 208.973 183.151C208.973 178.619 205.294 174.946 200.755 174.946C196.217 174.946 192.538 178.619 192.538 183.151C192.538 187.683 196.217 191.357 200.755 191.357Z"
      fill="#A5A5A5"
    />
    <path
      d="M237.437 225.598H164.563V241.435H237.437V225.598Z"
      fill="#E6E6E6"
    />
    <path
      opacity="0.4"
      d="M97.3728 98.4866C97.3728 98.4866 105.581 93.5881 107.827 102.446C110.074 111.303 111.763 121.119 119.568 123.43C127.373 125.74 99.9936 116.096 99.9936 116.096L97.3728 98.4866Z"
      fill="#A5A5A5"
    />
    <path
      d="M113.971 84.213C115.949 84.213 117.552 82.6121 117.552 80.6374C117.552 78.6626 115.949 77.0618 113.971 77.0618C111.994 77.0618 110.39 78.6626 110.39 80.6374C110.39 82.6121 111.994 84.213 113.971 84.213Z"
      fill="#24285B"
    />
    <path
      d="M124.618 84.213C126.595 84.213 128.198 82.6121 128.198 80.6374C128.198 78.6626 126.595 77.0618 124.618 77.0618C122.64 77.0618 121.037 78.6626 121.037 80.6374C121.037 82.6121 122.64 84.213 124.618 84.213Z"
      fill="#68E1FD"
    />
    <path
      d="M134.65 84.213C136.627 84.213 138.23 82.6121 138.23 80.6374C138.23 78.6626 136.627 77.0618 134.65 77.0618C132.672 77.0618 131.069 78.6626 131.069 80.6374C131.069 82.6121 132.672 84.213 134.65 84.213Z"
      fill="#FFD200"
    />
    <path
      d="M87.0049 238.137C87.0049 238.137 85.1905 240.706 82.5505 240.217C79.9105 239.729 78.5473 242.068 80.6881 243.362C82.8289 244.656 89.6257 243.304 89.6257 243.304L90.4801 239.537L87.0049 238.137Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.4"
      d="M133.555 126.469C133.555 126.469 143.213 126.516 146.237 146.388C149.261 166.26 152.88 199.256 119.568 199.601V175.99L133.555 126.469Z"
      fill="#A5A5A5"
    />
    <path
      d="M105.178 116.125C105.178 116.125 132.422 116.125 135.629 131.223C138.835 146.321 121.459 191.472 121.459 191.472L90.7392 190.149C90.7392 190.149 92.0256 173.354 87.3888 159.771C82.752 146.187 65.4624 121.781 105.178 116.125Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.08"
      d="M119.568 133.045C119.568 133.045 110.928 141.739 115.286 155.515C119.645 169.29 89.6255 168.935 89.6255 168.935C90.0909 171.815 90.4113 174.717 90.5855 177.63C90.5855 177.63 103.68 187.455 123.84 184.934L125.049 181.483L130.224 168.791L119.568 133.045Z"
      fill="black"
    />
    <path
      d="M86.6688 101.075C86.6688 101.075 88.4832 106.616 90.8928 109.779C91.1265 110.086 91.4187 110.344 91.7526 110.538C92.0864 110.731 92.4554 110.857 92.8382 110.908C93.2209 110.959 93.61 110.933 93.983 110.833C94.3559 110.734 94.7054 110.561 95.0112 110.325C96.0438 109.63 96.8525 108.65 97.3398 107.505C97.827 106.36 97.9718 105.099 97.7568 103.874L97.3728 98.5154C97.1821 97.4504 96.665 96.4708 95.8929 95.7119C95.1208 94.9529 94.1318 94.452 93.0624 94.2783C89.1072 93.3868 85.44 97.7581 86.6688 101.075Z"
      fill="#F4A28C"
    />
    <path
      d="M98.5344 101.497L105.178 116.125L96.5281 122.538L94.8 108.773L98.5344 101.497Z"
      fill="#F4A28C"
    />
    <path
      d="M88.0992 100.739C89.7889 100.098 91.3994 99.2646 92.8992 98.2567C92.7302 99.1881 92.8384 100.148 93.2104 101.019C93.5823 101.89 94.2017 102.632 94.992 103.155C95.7464 103.68 96.6603 103.926 97.5766 103.851C98.4929 103.775 99.3543 103.384 100.013 102.743L96.96 95.8985C96.5658 94.9094 95.9119 94.0447 95.0673 93.3953C94.2226 92.7458 93.2183 92.3356 92.16 92.2078C91.2363 92.0968 90.3062 92.0488 89.376 92.064C87.0528 92.064 84.7104 94.6619 82.3584 94.0867C82.1204 94.0226 81.8697 94.0222 81.6316 94.0857C81.3935 94.1492 81.1763 94.2743 81.0021 94.4483C80.8278 94.6223 80.7026 94.8391 80.639 95.0769C80.5754 95.3147 80.5757 95.565 80.64 95.8026C81.2256 98.0266 83.0016 101.161 86.3232 101.104C86.931 101.079 87.5307 100.956 88.0992 100.739V100.739Z"
      fill="#24285B"
    />
    <path
      d="M94.3297 103.28C94.3297 103.28 93.4945 101.2 95.1073 100.701C96.7201 100.203 97.9873 103.203 95.9713 104.373L94.3297 103.28Z"
      fill="#F4A28C"
    />
    <path
      d="M87.9937 104.507L87.3601 107.21C87.3334 107.361 87.3434 107.516 87.3893 107.663C87.4351 107.809 87.5155 107.943 87.6237 108.052C87.7319 108.161 87.8647 108.242 88.0109 108.289C88.1571 108.336 88.3125 108.347 88.4641 108.322L90.6817 107.843L87.9937 104.507Z"
      fill="#F4A28C"
    />
    <path
      opacity="0.31"
      d="M95.04 110.326C95.04 110.326 97.0176 107.613 97.0272 105.858C97.7698 107.396 98.0358 109.119 97.7912 110.808C97.5467 112.497 96.8026 114.075 95.6544 115.339L95.04 110.326Z"
      fill="#CE8172"
    />
    <path
      d="M122.073 128.386C122.944 127.4 124.05 126.651 125.29 126.209C126.529 125.766 127.86 125.645 129.159 125.857C130.458 126.069 131.682 126.606 132.716 127.419C133.75 128.232 134.561 129.293 135.072 130.504C138.749 139.132 142.963 154.469 136.665 171.13C127.065 196.6 89.0303 168.484 89.0303 168.484L94.7903 162.253C94.7903 162.253 114.797 171.696 122.736 166.788C129.321 162.714 112.195 139.841 122.073 128.386Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.2"
      d="M122.073 128.386C122.944 127.4 124.05 126.651 125.29 126.209C126.529 125.766 127.86 125.645 129.159 125.857C130.458 126.069 131.682 126.606 132.716 127.419C133.75 128.232 134.561 129.293 135.072 130.504C138.749 139.132 142.963 154.469 136.665 171.13C127.065 196.6 89.0303 168.484 89.0303 168.484L94.7903 162.253C94.7903 162.253 114.797 171.696 122.736 166.788C129.321 162.714 112.195 139.841 122.073 128.386Z"
      fill="white"
    />
    <path
      d="M89.8176 167.631C89.8176 167.631 83.1552 166.328 82.656 161.046C82.1568 155.764 89.9616 163.557 91.344 161.046C92.7264 158.534 95.3568 158.438 94.7424 162.253L89.8176 167.631Z"
      fill="#F4A28C"
    />
    <path
      d="M114.029 227.209C114.029 227.209 116.371 229.308 115.565 231.868C114.758 234.427 116.918 236.066 118.445 234.101C119.971 232.136 119.482 225.234 119.482 225.234L115.843 223.93L114.029 227.209Z"
      fill="#68E1FD"
    />
    <path
      d="M90.4801 239.537L85.2769 238.137L99.9937 197.032L94.0801 190.254L121.459 191.472C121.323 193.943 120.665 196.357 119.528 198.556C118.391 200.755 116.802 202.689 114.864 204.231C112.319 206.201 110.021 208.47 108.019 210.989C101.183 219.862 95.3045 229.431 90.4801 239.537V239.537Z"
      fill="#24285B"
    />
    <path
      d="M90.7009 190.101L89.8849 210.136C89.8055 211.992 90.2747 213.831 91.234 215.423C92.1933 217.015 93.6004 218.29 95.2801 219.089L114.48 228.263L116.4 223.633L105.245 215.543C103.803 214.497 102.733 213.02 102.189 211.325C101.645 209.631 101.656 207.807 102.221 206.119L107.318 190.782L90.7009 190.101Z"
      fill="#24285B"
    />
    <path
      d="M210.49 109.865H205.498V98.1991C205.548 97.2237 205.399 96.2483 205.06 95.3321C204.721 94.416 204.199 93.5784 203.525 92.8702C202.852 92.162 202.041 91.598 201.142 91.2126C200.244 90.8273 199.276 90.6285 198.298 90.6285C197.319 90.6285 196.352 90.8273 195.453 91.2126C194.554 91.598 193.743 92.162 193.07 92.8702C192.396 93.5784 191.874 94.416 191.535 95.3321C191.196 96.2483 191.047 97.2237 191.098 98.1991V109.865H186.096V98.1991C186.185 95.0256 187.51 92.012 189.79 89.7988C192.069 87.5856 195.123 86.3474 198.302 86.3474C201.482 86.3474 204.536 87.5856 206.815 89.7988C209.094 92.012 210.42 95.0256 210.509 98.1991L210.49 109.865Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.46"
      d="M210.49 109.865H205.498V98.1991C205.548 97.2237 205.399 96.2483 205.06 95.3321C204.721 94.416 204.199 93.5784 203.525 92.8702C202.852 92.162 202.041 91.598 201.142 91.2126C200.244 90.8273 199.276 90.6285 198.298 90.6285C197.319 90.6285 196.352 90.8273 195.453 91.2126C194.554 91.598 193.743 92.162 193.07 92.8702C192.396 93.5784 191.874 94.416 191.535 95.3321C191.196 96.2483 191.047 97.2237 191.098 98.1991V109.865H186.096V98.1991C186.185 95.0256 187.51 92.012 189.79 89.7988C192.069 87.5856 195.123 86.3474 198.302 86.3474C201.482 86.3474 204.536 87.5856 206.815 89.7988C209.094 92.012 210.42 95.0256 210.509 98.1991L210.49 109.865Z"
      fill="white"
    />
    <path d="M216.96 100.95H179.616V130.677H216.96V100.95Z" fill="#68E1FD" />
    <path
      d="M201.677 111.754C201.703 111.221 201.621 110.688 201.435 110.187C201.248 109.687 200.962 109.23 200.594 108.843C200.225 108.457 199.782 108.149 199.291 107.938C198.799 107.728 198.27 107.62 197.736 107.62C197.201 107.62 196.672 107.728 196.181 107.938C195.69 108.149 195.246 108.457 194.878 108.843C194.509 109.23 194.223 109.687 194.037 110.187C193.851 110.688 193.769 111.221 193.795 111.754C193.792 112.296 193.904 112.833 194.125 113.328C194.345 113.824 194.668 114.268 195.072 114.63V122.989H200.486V114.572C200.862 114.205 201.161 113.766 201.365 113.282C201.569 112.799 201.675 112.279 201.677 111.754Z"
      fill="#24285B"
    />
    <path d="M233.645 142.056H162.922V157.048H233.645V142.056Z" fill="white" />
    <path
      d="M233.645 142.056H211.814V157.048H233.645V142.056Z"
      fill="#FFD200"
    />
    <path
      d="M171.984 151.738C173.124 151.738 174.048 150.815 174.048 149.676C174.048 148.538 173.124 147.615 171.984 147.615C170.844 147.615 169.92 148.538 169.92 149.676C169.92 150.815 170.844 151.738 171.984 151.738Z"
      fill="#24285B"
    />
    <path
      d="M179.654 151.738C180.794 151.738 181.718 150.815 181.718 149.676C181.718 148.538 180.794 147.615 179.654 147.615C178.514 147.615 177.59 148.538 177.59 149.676C177.59 150.815 178.514 151.738 179.654 151.738Z"
      fill="#24285B"
    />
    <path
      d="M187.142 151.738C188.282 151.738 189.206 150.815 189.206 149.676C189.206 148.538 188.282 147.615 187.142 147.615C186.002 147.615 185.078 148.538 185.078 149.676C185.078 150.815 186.002 151.738 187.142 151.738Z"
      fill="#24285B"
    />
    <path
      d="M194.813 151.738C195.953 151.738 196.877 150.815 196.877 149.676C196.877 148.538 195.953 147.615 194.813 147.615C193.673 147.615 192.749 148.538 192.749 149.676C192.749 150.815 193.673 151.738 194.813 151.738Z"
      fill="#24285B"
    />
    <path
      d="M202.656 151.738C203.796 151.738 204.72 150.815 204.72 149.676C204.72 148.538 203.796 147.615 202.656 147.615C201.516 147.615 200.592 148.538 200.592 149.676C200.592 150.815 201.516 151.738 202.656 151.738Z"
      fill="#24285B"
    />
    <path
      d="M57.1104 197.042C57.1104 197.042 47.5104 197.31 48 191.424C48.4896 185.538 56.4768 191.347 56.4768 191.347L57.1104 197.042Z"
      fill="#F4A28C"
    />
    <path
      d="M46.2955 177.146L40.5886 186.098L56.0646 195.935L61.7714 186.983L46.2955 177.146Z"
      fill="#FFD200"
    />
    <path
      opacity="0.4"
      d="M301.891 104.229H271.421V120.679H301.891V104.229Z"
      fill="#A5A5A5"
    />
    <path
      opacity="0.12"
      d="M47.9999 166.232C47.9999 166.232 20.7743 62.5195 80.6399 32.8217C94.1567 26.1115 179.702 8.85647 260.525 50.2397"
      stroke="black"
      strokeWidth="2.03"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2.03 10.15"
    />
    <path
      opacity="0.58"
      d="M309.437 232.222C309.437 232.222 301.267 229.989 299.491 222.387C299.491 222.387 312.154 219.837 312.509 232.932L309.437 232.222Z"
      fill="#68E1FD"
    />
    <path
      opacity="0.73"
      d="M310.445 231.408C310.445 231.408 304.733 222.397 309.754 213.961C309.754 213.961 319.354 220.067 315.111 231.427L310.445 231.408Z"
      fill="#68E1FD"
    />
    <path
      d="M311.914 231.417C311.914 231.417 314.928 221.898 324.048 220.086C324.048 220.086 325.766 226.269 318.144 231.436L311.914 231.417Z"
      fill="#68E1FD"
    />
    <path
      d="M305.981 231.197L307.641 242.518L318.077 242.566L319.613 231.254L305.981 231.197Z"
      fill="#24285B"
    />
    <path d="M315.043 54.7932H264V105.763H315.043V54.7932Z" fill="#24285B" />
    <path
      d="M279.523 79.7939L285.456 89.6484L299.683 70.8979"
      stroke="white"
      strokeWidth="5.075"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
