import { Store, Network, Environment, RecordSource } from "relay-runtime";

import fetchFn from "./fetchQuery";
import { subscribeFn } from "./subscribeFn";

export interface RecordMap {
  // theoretically, this should be `[dataID: DataID]`, but `DataID` is a string.
  [dataID: string]: Record<string, unknown> | undefined;
}

interface CreateEnvironmentProps {
  rejectErrors?: boolean;
  relayRecords?: RecordMap;
  endpoints: { graphql: string; websocket: string };
  ssrToken?: string;
  redirect: (path: string) => void;
  isK8?: boolean;
}

let environment: Environment | null = null;

export const createRelayEnvironment = ({
  endpoints,
  rejectErrors = false,
  relayRecords: records = {},
  ssrToken,
  redirect,
  isK8,
}: CreateEnvironmentProps) => {
  if (process.browser && environment) {
    return environment;
  }

  // @ts-ignore
  const store = new Store(new RecordSource(records));
  const network = Network.create(
    fetchFn({
      endpoint: endpoints.graphql,
      rejectErrors,
      ssrToken,
      redirect,
      isK8,
    }),
    subscribeFn({
      endpoint: endpoints.websocket,
      connectionParams: {},
    }),
  );

  environment = new Environment({ store, network });
  return environment;
};
