import { SubscriptionClient } from "subscriptions-transport-ws";
import { Observable, SubscribeFunction, GraphQLResponse } from "relay-runtime";

interface Props {
  endpoint: string;
  connectionParams?: Record<string, any>;
}

const fn =
  ({ endpoint, connectionParams = {} }: Props): SubscribeFunction =>
  (request, variables) => {
    const query = request.text;
    if (!query) {
      throw Error("setupSubscription is missing observer or query");
    }
    const ws = process.browser ? undefined : require("ws");

    const client = new SubscriptionClient(
      endpoint,
      { reconnect: true, connectionParams },
      ws,
    );

    const observable = Observable.create<GraphQLResponse>((sink) => {
      const subscriber = client.request({ query, variables }).subscribe({
        // @ts-ignore
        next: sink.next,
        error: sink.error,
        complete: sink.complete,
      });
      return () => subscriber.unsubscribe();
    });
    return observable;
  };

// const Logger = createLogger(logger);

export const subscribeFn = fn;

// export const subscribeFn =
//   process.env.NODE_ENV !== "production"
//     ? (props: Props) =>
//         Logger.wrapSubscribe(fn(props), createGraphiQLPrinter(props.endpoint))
//     : fn;
