import { styled } from "@styles/theme";

export const HeaderGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(145.31deg, #805ad5 0%, #4299e1 100%);
  border-top-left-radius: ${(p) => p.theme.radius};
  border-top-right-radius: ${(p) => p.theme.radius};
`;
