import React, { createContext, useContext, useEffect } from "react";
import { animated, useTransition } from "react-spring";

import { styled } from "@styles/theme";

import Button from "./Button";
import { Flex } from "./ResponsiveBox";
import { Icon } from "./Icon";

const CustomMessage = styled(animated(Flex))`
  z-index: 99999;
  bottom: 48px;
  right: 48px;
`;

export interface ToastProps {
  id: string;
  content: React.ReactNode;
  action: {
    label: string;
    onClick: () => void;
  };
  onClose?: () => void;
}

export function Toast() {
  const { toasts, removeToast } = useToast();

  useEffect(() => {
    if (toasts.length > 0) {
      if (toasts.length > 1) {
        removeToast(toasts[0].id);
      }

      const timeout = setTimeout(() => {
        toasts.map(({ id }) => removeToast(id));
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [toasts]);

  const withTransition = useTransition(toasts, (toast) => toast.id, {
    from: { opacity: 0, bottom: "-100px" },
    enter: { opacity: 1, bottom: "48px" },
    leave: { opacity: 0, bottom: "-100px" },
  });

  return (
    <>
      {withTransition.map(({ item, key, props }) => (
        <CustomMessage
          {...item}
          id={item.id}
          key={key}
          direction="row"
          alignItems="center"
          position="fixed"
          maxW="80vw"
          bg="blue.600"
          pY="sm"
          pX="lg"
          round
          shadow="xs"
          style={props as object}
        >
          {item.content}

          <Button
            small
            color="white"
            mL="lg"
            onPress={(e) => {
              e.stopPropagation();
              item.action.onClick();
              removeToast(item.id);
            }}
          >
            {item.action.label}
          </Button>

          <Icon
            name="close"
            size={24}
            fill="white.0"
            pL="md"
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              removeToast(item.id);
              item?.onClose?.();
            }}
          />
        </CustomMessage>
      ))}
    </>
  );
}

export const useToast = () => {
  const { toasts, addToast, removeToast } = useContext(ToastContext);

  return { toasts, addToast, removeToast };
};

export const ToastContext = createContext(
  {} as {
    toasts: ToastProps[];
    addToast: (
      newToast: Omit<ToastProps, "id"> & { id?: string | undefined },
    ) => string;
    removeToast: (id?: string) => void;
  },
);
