import CryptoJS from "crypto-js";
import { PageContext } from "pages/_app";

const encode = (user: any, secret: string) =>
  CryptoJS.AES.encrypt(JSON.stringify(user), secret).toString();

export const getSsrToken = (ctx: PageContext, ssrSecret: string) => {
  if (process.browser) return null;
  if (ctx.req?.ssrToken) return ctx.req.ssrToken;
  if (ctx.req?.user) return encode(ctx.req.user, ssrSecret);
};
