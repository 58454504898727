import { PageContext } from "pages/_app";
import { ConfigVariables } from "../contexts/ConfigVariables";

export function getConfigVariablesFromCtx(ctx: PageContext): ConfigVariables {
  const variables = ctx.res?.clientConfigVariables;

  const hotjarId = Number(variables?.HOTJAR_ID);
  const hotjarVersion = Number(variables?.HOTJAR_VERSION);

  return {
    MIXPANEL_TOKEN: variables?.MIXPANEL_TOKEN,
    Mixpanel: undefined,
    CODA_ENV: variables?.CODA_ENV,
    HOTJAR_ID: isNaN(hotjarId) ? undefined : hotjarId,
    HOTJAR_VERSION: isNaN(hotjarVersion) ? undefined : hotjarVersion,
    HTTPS: (variables?.HTTPS as unknown as string) == "true",
    K8: (variables?.K8 as unknown as string) == "true",
    CODA_LP_URL: variables?.CODA_LP_URL,
    STORAGE_NAME: variables?.STORAGE_NAME,
    OAUTH_CLIENT_ID: variables?.OAUTH_CLIENT_ID,
    CODA_URL: variables?.CODA_URL,
  };
}
